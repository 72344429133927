import React from "react";
import logo from "../images/logo_white_small.png";
import "./main-menu.css";

function MainMenu(props) {
  return (
    <div className="main-menu">
      <div>
        <a href="/" className="logo-link">
          <img alt="Ohmi logo" src={logo} className="logo" />
        </a>
      </div>
      <div className="menu">
        {/* <a className={props.currentRoute === "home" ? "active" : ""} href="/">
          Hem
        </a> */}
        <a
          className={props.currentRoute === "about-us" ? "active" : ""}
          href="/about-us"
        >
          About us
        </a>
        <button
          className="download-cta"
          href="#"
          onClick={() => props.setShowModal(!props.showModal)}
        >
          Download
        </button>
      </div>
    </div>
  );
}

export default MainMenu;
