import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UILayout from "./ui/ui-layout";
import Home from "./views/home";
import AboutUs from "./views/about-us";
import "./App.css";
import "./ui/variables.css";
import TermsPrivacy from "./views/terms-privacy";

function App() {
  const [currentRoute, setCurrentRoute] = useState("/");
  useEffect(() => {
    console.log("CURRENT ROUTE", currentRoute);
  }, [currentRoute]);
  return (
    <div className="App">
      <Router>
        <UILayout currentRoute={currentRoute} setCurrentRoute={setCurrentRoute}>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
            <Route
              path="/about-us"
              element={
                <AboutUs
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <TermsPrivacy
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
          </Routes>
        </UILayout>
      </Router>
    </div>
  );
}

export default App;
